import { Exercise } from "@/utils/entities";
import { useEffect, useState } from "react";
import { Button } from "@/components/ui/button";
import SheetExerciseVideo from "./sheet-exercise-video";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  DumbbellIcon,
  EditIcon,
  Loader2Icon,
  Trash2Icon,
} from "lucide-react";
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
} from "@/components/ui/table";

type Props = {
  sheetId: string;
  isLoading?: boolean;
  exercises: Exercise[];
  handleEdit?: (item: any) => void;
  handleRemove: (item: any, index: number) => void;
  handleUpdateOrderListOfExercises: (items: Exercise[]) => void;
  handleShowExecutions?: (item: any) => void;
};

const SheetTableExercisesAdded = ({
  exercises,
  handleEdit,
  handleRemove,
}: Props) => {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Vídeo</TableHead>
          <TableHead>Nome</TableHead>
          <TableHead>Tipo</TableHead>
          <TableHead>Plano de execução</TableHead>
          <TableHead>Observação</TableHead>
          <TableHead className="w-[100px]" />
        </TableRow>
      </TableHeader>

      <TableBody className="text-gray-200">
        {exercises?.map((item, index) => (
          <TableRow key={item.id + "_" + index}>
            <TableCell>
              <SheetExerciseVideo
                exercise={item}
                className="w-[100px] h-[100px]"
              />
            </TableCell>
            <TableCell>{item.name}</TableCell>
            <TableCell>{item.type.name}</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell>
              <div className="flex items-center gap-3">
                <Button
                  variant="secondary"
                  className="gap-1"
                  onClick={() => handleEdit?.(item)}
                >
                  <EditIcon size={14} /> Editar
                </Button>
                <Button
                  variant="secondary"
                  className="gap-1"
                  onClick={() => handleRemove?.(item, index)}
                >
                  <Trash2Icon size={14} /> Remover
                </Button>
              </div>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

const SheetGridExercisesAdded = ({
  sheetId,
  exercises,
  isLoading,
  handleEdit,
  handleRemove,
  handleShowExecutions,
  handleUpdateOrderListOfExercises,
}: Props) => {
  const [data, setData] = useState<Exercise[]>(exercises);

  useEffect(() => {
    setData(exercises);
  }, [exercises]);

  function updateExerciseOrder(
    exercise: Exercise,
    sourceIndex: number,
    destinationIndex: number
  ) {
    const items = [...data];

    if (sourceIndex === destinationIndex) {
      return;
    }

    if (sourceIndex === -1 || destinationIndex === -1) {
      return;
    }

    items.splice(sourceIndex, 1);
    items.splice(destinationIndex, 0, exercise);

    setData(items);
    handleUpdateOrderListOfExercises(items);
  }

  return (
    <div className="flex flex-col gap-6 mt-4">
      {data.map((exercise, exercise_index) => (
        <div
          className={"p-4 bg-primary border rounded-md relative overflow-auto"}
        >
          <div className="h-full flex flex-1 gap-4">
            <div className="flex flex-col">
              <button
                className={`${
                  exercise_index === 0
                    ? "opacity-0 flex-1 cursor-default"
                    : "flex-1 transition-all text-white hover:bg-white hover:text-black rounded-full"
                }`}
                onClick={() =>
                  updateExerciseOrder(
                    exercise,
                    exercise_index,
                    exercise_index - 1
                  )
                }
              >
                <ArrowUpIcon />
              </button>
              <button
                className={`${
                  exercise_index === data.length - 1
                    ? "opacity-0 flex-1 cursor-default"
                    : "flex-1 transition-all text-white hover:bg-white hover:text-black rounded-full"
                }`}
                onClick={() =>
                  updateExerciseOrder(
                    exercise,
                    exercise_index,
                    exercise_index + 1
                  )
                }
              >
                <ArrowDownIcon />
              </button>
            </div>

            <SheetExerciseVideo exercise={exercise} />

            <div className="flex flex-col flex-1 text-white">
              <div className="flex items-center">
                <span className="text-xl font-bold border rounded-md w-10 h-10 mr-2 justify-center items-center flex">
                  {(exercise?.execution?.order || 0) + 1 + "º"}
                </span>
                <h3 className="text-sm font-semibold flex-1">
                  {exercise?.name}
                </h3>
                <div className="px-4 py-2 rounded-md bg-primary text-primary-foreground border text-sm">
                  {exercise?.type?.name}
                </div>
              </div>

              <div className="flex-1 h-full gap-2 grid grid-cols-3 py-2">
                <div className="flex flex-1 flex-col">
                  <span>Series</span>
                  <span className="text-[11px]">
                    {exercise?.execution?.sets || "-"}
                  </span>
                </div>

                <div className="flex flex-1 flex-col">
                  <span>Repet.</span>
                  <span className="text-[11px]">
                    {exercise?.execution?.repetitions || "-"}
                  </span>
                </div>

                <div className="flex flex-1 flex-col">
                  <span>Cadência</span>
                  <span className="text-[11px]">
                    {exercise?.execution?.cadence || "-"}
                  </span>
                </div>

                <div className="flex flex-1 flex-col">
                  <span>Intervalo</span>
                  <span className="text-[11px]">
                    {exercise?.execution?.interval || "-"}
                  </span>
                </div>

                <div className="flex flex-1  flex-col">
                  <span>Carga Sugerida</span>
                  <span className="text-[11px]">
                    {exercise?.execution?.load || "-"}
                  </span>
                </div>

                <div className="flex flex-1  flex-col">
                  <span>Observação</span>
                  <span className="text-[11px]">
                    {exercise?.execution?.observation || "-"}
                  </span>
                </div>
              </div>

              <div className="flex gap-2 mt-4 justify-between">
                {handleShowExecutions && (
                  <Button
                    size="xs"
                    type="button"
                    onClick={() => handleShowExecutions(exercise)}
                    variant="secondary"
                    className="flex px-4"
                  >
                    Execuções <DumbbellIcon className="ml-2" size={16} />
                  </Button>
                )}

                <div className="flex flex-row gap-2">
                  {handleEdit && Boolean(sheetId) ? (
                    <Button
                      size="xs"
                      type="button"
                      variant="secondary"
                      className="flex px-4"
                      onClick={() => handleEdit(exercise)}
                    >
                      Editar <EditIcon className="ml-2" size={16} />
                    </Button>
                  ) : null}

                  <Button
                    size="xs"
                    type="button"
                    variant="secondary"
                    className="flex px-4"
                    onClick={() => handleRemove(exercise, exercise_index)}
                  >
                    Remover da 'letra' <Trash2Icon className="ml-2" size={16} />
                  </Button>
                </div>
              </div>
            </div>
          </div>

          {isLoading && (
            <div className="absolute top-0 right-0 left-0 bottom-0 backdrop-blur-sm">
              <Loader2Icon className="h-5 w-5 animate-spin m-5 mx-auto" />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export { SheetTableExercisesAdded, SheetGridExercisesAdded };
