import SheetDialogExerciseExecution from "./sheet-dialog-exercise-execution";
import { SheetGridExercisesAdded } from "./sheet-list-exercises-added";
import { SheetTrainingService } from "@/services/api/sheet-training";
import SheetDialogEditExercise from "./sheet-dialog-edit-exercise";
import SheetDialogAddExercise from "./sheet-dialog-add-exercise";
import { Exercise, Sheet, Training } from "@/utils/entities";
import ModalConfirmDelete from "@/components/modal-confirm";
import ModalAddLetter from "../modal/modal-add-letter";
import { useToast } from "@/components/ui/use-toast";
import { SheetService } from "@/services/api/sheet";
import { useSearchParams } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { useMemo, useState } from "react";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  Trash2Icon,
  PlusIcon,
  Loader2,
} from "lucide-react";

type Props = {
  data: Sheet | undefined;
  sheetId?: string | undefined;
  isLoading: boolean;
  onSuccess: () => void;
  onRemoveExercise: (exercise: Exercise) => void;
};

type SheetDialogTypes = "ADD" | "EDIT" | "DELETE" | "LIST_EXECUTION";

export default function SheetTrainingContent({
  data,
  sheetId,
  isLoading,
  onSuccess,
  onRemoveExercise,
}: Props) {
  const { toast } = useToast();
  const [exercise, setExercise] = useState<Exercise>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [dialogType, setDialogType] = useState<SheetDialogTypes>();
  const [activeTraining, setActiveTraining] = useState<Training>();
  const [isUpdatingTabs, setUpdatingTabs] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const currentTab = useMemo(() => {
    return data?.trainings.find(
      (item) => item.id === searchParams.get("tabLetterKey")
    );
  }, [data, searchParams]);

  function handleEdit(item: Exercise) {
    setExercise(item);
    setDialogType("EDIT");
  }

  function handleDelete(item: Exercise) {
    setExercise(item);
    setDialogType("DELETE");
  }

  function handleListExecution(item: Exercise) {
    setExercise(item);
    setDialogType("LIST_EXECUTION");
  }

  function handleCloseDialog() {
    setExercise(undefined);
    setDialogType(undefined);
    setActiveTraining(undefined);
  }

  function handleAddExercise() {
    setActiveTraining(currentTab);
    setDialogType("ADD");
  }

  function handleRemoveExercise(item: Exercise) {
    setExercise(undefined);
    setDialogType(undefined);
    setActiveTraining(undefined);

    onRemoveExercise?.(item);
  }

  function handleTrainingUpdated() {
    handleCloseDialog();
    onSuccess?.();
  }

  async function updateOrderListOfExercises(
    trainingId: string,
    items: Exercise[]
  ) {
    setUpdatingTabs(true);
    try {
      if (!sheetId) return;

      const exercises = items.map(({ execution }, order) => ({
        id: execution!.id!,
        order,
      }));

      await SheetTrainingService.updateExerciseOrder({
        sheetId,
        exercises,
        trainingId,
      });

      handleTrainingUpdated();
    } catch (error) {
      toast({
        variant: "default",
        description: "Erro ao reordenar exercíceos",
      });
    }
    setUpdatingTabs(false);
  }

  const moveTrainingToNext = async (trainingId: string, index: number) => {
    if (
      data?.trainings?.length &&
      (!sheetId || index >= data.trainings.length - 1 || !data?.trainings)
    ) {
      return;
    }
    setUpdatingTabs(true);

    await SheetTrainingService.updateTrainingOrder({
      sheetId: sheetId!,
      index,
      trainingId,
      type: "NEXT",
    });

    onSuccess?.();
    setUpdatingTabs(false);
  };

  const moveTrainingToPrevious = async (trainingId: string, index: number) => {
    if (!sheetId || index <= 0 || !data?.trainings) {
      return;
    }
    setUpdatingTabs(true);

    await SheetTrainingService.updateTrainingOrder({
      sheetId,
      index,
      trainingId,
      type: "PREVIOUS",
    });

    onSuccess?.();
    setUpdatingTabs(false);
  };

  async function onCreateLetter(name: string) {
    if (!sheetId || !data?.trainings) {
      return;
    }

    await SheetService.createLetter(sheetId, {
      name,
      exercises: [],
      index: data?.trainings.length,
    });

    onSuccess?.();
  }

  const handleUpdateLetter = async (id: string, name: string) => {
    setUpdatingTabs(true);
    await SheetService.updateLetter(id, name);
    onSuccess?.();
    setUpdatingTabs(false);
  };

  function handleChangeTabLetter(id: string) {
    searchParams.set("tabLetterKey", id);

    setSearchParams(searchParams, { replace: true });
  }

  async function deleteCurrentTab() {
    setUpdatingTabs(true);
    setIsDeleting(false);
    if (currentTab?.id) {
      await SheetService.deleteLetter(currentTab?.id);
    }
    onSuccess?.();
    toast({
      variant: "success",
      description: "Letra deletada com sucesso",
    });
    setUpdatingTabs(false);
  }

  return (
    <>
      <div className="grid grid-cols-[300px,1fr] gap-4 overflow-auto">
        <div className="flex flex-col gap-4">
          {!sheetId || !data?.trainings || data?.trainings?.length === 0 ? (
            <div className="flex min-h-[100px]">
              <span className="m-auto text-zinc-300">
                Não há Letra cadastrado o treino
              </span>
            </div>
          ) : (
            data?.trainings.map((item, index) => (
              <div className="flex">
                <div>
                  <button
                    className={`${
                      index === 0
                        ? "opacity-0"
                        : "transition-all text-white hover:bg-white hover:text-black rounded-full"
                    }`}
                    onClick={() => moveTrainingToPrevious(item.id!, index)}
                  >
                    <ArrowUpIcon />
                  </button>
                  <button
                    className={`${
                      index === data.trainings.length - 1
                        ? "opacity-0"
                        : "transition-all text-white hover:bg-white hover:text-black rounded-full"
                    }`}
                    onClick={() => moveTrainingToNext(item.id!, index)}
                  >
                    <ArrowDownIcon />
                  </button>
                </div>
                <button
                  type="button"
                  key={item.id}
                  onClick={() => handleChangeTabLetter(item?.id || "")}
                  className={`rounded-md w-full truncate p-2 shadow-sm border border-white hover:opacity-65  transition-all text-left shadow-white ${
                    item.id === currentTab?.id
                      ? "bg-primary text-white"
                      : "bg-white"
                  }`}
                >
                  {item.name}
                </button>
              </div>
            ))
          )}
          <ModalAddLetter onSave={onCreateLetter} />
        </div>

        <div>
          <div className="flex w-full items-center border-b pb-2">
            <span className="text-2xl text-white font-semibold">
              {currentTab?.name || ""}
            </span>

            {currentTab && (
              <div className="flex-1 flex justify-end">
                <ModalAddLetter
                  isUpdate
                  letter={currentTab?.name}
                  onSave={(name) => handleUpdateLetter(currentTab?.id!, name)}
                />

                <Button
                  onClick={() => setIsDeleting(true)}
                  className="ml-4"
                  variant={"destructive"}
                >
                  <Trash2Icon className="mr-2 w-4 h-4" /> Remover "Letra"
                </Button>
              </div>
            )}
          </div>

          {!currentTab?.exercises ||
            (currentTab?.exercises.length === 0 && (
              <div className="flex min-h-[100px]">
                <span className="m-auto text-zinc-300">
                  Não há exerciceos cadastrados para essa letra
                </span>
              </div>
            ))}
          <div className="flex flex-col">
            <SheetGridExercisesAdded
              sheetId={sheetId as string}
              isLoading={isLoading || isUpdatingTabs}
              exercises={currentTab?.exercises || []}
              handleEdit={(exercise: Exercise) => handleEdit(exercise)}
              handleRemove={(exercise: Exercise) => handleDelete(exercise)}
              handleShowExecutions={(exercise: Exercise) =>
                handleListExecution(exercise)
              }
              handleUpdateOrderListOfExercises={(exercises) =>
                updateOrderListOfExercises(currentTab?.id!, exercises)
              }
            />

            {currentTab && (
              <Button
                size="sm"
                type="button"
                className="mt-5"
                variant="secondary"
                onClick={() => handleAddExercise()}
              >
                <PlusIcon className="h-4 w-4 mr-1" /> Adicionar exercício
              </Button>
            )}
          </div>
        </div>
      </div>

      {dialogType === "ADD" && activeTraining?.id ? (
        <SheetDialogAddExercise
          isOpened={true}
          trainingId={activeTraining.id}
          onCancel={() => handleCloseDialog()}
          onChange={() => handleTrainingUpdated()}
        />
      ) : null}

      {dialogType === "EDIT" && exercise ? (
        <SheetDialogEditExercise
          isOpened={true}
          exercise={exercise}
          onCancel={() => handleCloseDialog()}
          onChange={() => handleTrainingUpdated()}
        />
      ) : null}

      {dialogType === "LIST_EXECUTION" && exercise ? (
        <SheetDialogExerciseExecution
          isOpened={true}
          exercise={exercise}
          handleClose={() => handleCloseDialog()}
        />
      ) : null}

      {dialogType === "DELETE" && exercise ? (
        <ModalConfirmDelete
          title="Confirmação"
          isVisible={true}
          onCancel={() => handleCloseDialog()}
          onContinue={() => handleRemoveExercise(exercise)}
          description={
            <span>
              Você tem certeza que quer <strong>remover</strong> o exercício{" "}
              {exercise?.name} do treino?
            </span>
          }
        />
      ) : null}

      <ModalConfirmDelete
        isLoading={false}
        onContinue={deleteCurrentTab}
        isVisible={isDeleting}
        title="Deletando 'Letar'"
        onCancel={() => setIsDeleting(false)}
        description={`Você quer deletar a 'Letra' ${currentTab?.name}?`}
      />

      {(isUpdatingTabs || isLoading) && (
        <div className="fixed top-0 bottom-0 left-0 right-0 bg-[#ffffff55] flex items-center justify-center">
          <Loader2 className="animate-spin" size={40} />
        </div>
      )}
    </>
  );
}
