import AccountActivateScreen from "@/features/account/activate";
import AccountForgotPasswordScreen from "@/features/account/forgot-password";
import HomeScreen from "@/features/home";
import ProfessionalCreateScreen from "@/features/professional/create";
import ProfessionalEditScreen from "@/features/professional/edit";
import ProfessionalListScreen from "@/features/professional/list";
import SignInScreen from "@/features/sign-in";
import SignUpScreen from "@/features/sign-up";
import StudentEditScreen from "@/features/student/edit";
import StudentListScreen from "@/features/student/list";
import StorageUser from "@/services/storage/user";
import { User, UserType } from "@/utils/entities";
import Providers from "@/utils/providers";
import { ReactNode } from "react";
import { Navigate, createBrowserRouter } from "react-router-dom";
import Routes from "./paths";
import ExerciseTypeListScreen from "@/features/exercise-type/list";
import ExerciseListScreen from "@/features/exercise/list";
import SheetCreateScreen from "@/features/student/sheet";
import SubscribeScreen from "@/features/subscribe";
import ConfigScreen from "@/features/config";
import MembersScreen from "@/features/members";
import TemplateListScreen from "@/features/template/list";
import SheetTemplateEditScreen from "@/features/template/edit";
import PrivacyScreen from "@/features/privacy";

type RouteProps = {
  Component: ReactNode;
};

function Route({ Component }: RouteProps) {
  return <Providers>{Component}</Providers>;
}

function PublicRoute({ Component }: RouteProps) {
  const isUserAuthenticated = !!StorageUser.get();

  if (isUserAuthenticated) {
    return <Navigate to={Routes.home} replace />;
  }

  return <Providers>{Component}</Providers>;
}

function PrivateRoute({ Component }: RouteProps) {
  const user = StorageUser.get() as User | null;

  if (!user) {
    return <Navigate to={Routes.base} replace />;
  }

  const isProfessional = user.type === UserType.PROFESSIONAL;

  if (isProfessional) {
    const isRouteInBlack = Object.values(Routes.professional).includes(
      window.location.pathname
    );

    if (isRouteInBlack) {
      return <Navigate to={Routes.base} replace />;
    }
  }

  return <Providers>{Component}</Providers>;
}

const Router = createBrowserRouter([
  {
    path: Routes.base,
    element: <PublicRoute Component={<SignInScreen />} />,
  },
  {
    path: Routes.privacy,
    element: <Route Component={<PrivacyScreen />} />,
  },
  {
    path: Routes.signIn,
    element: <PublicRoute Component={<SignInScreen />} />,
  },
  {
    path: Routes.professional.subscribe,
    element: <Route Component={<SubscribeScreen />} />,
  },
  {
    path: Routes.account.forgotPassword,
    element: <Route Component={<AccountForgotPasswordScreen />} />,
  },
  {
    path: Routes.account.activate,
    element: <Route Component={<AccountActivateScreen />} />,
  },
  {
    path: Routes.signUp,
    element: <Route Component={<SignUpScreen />} />,
  },
  {
    path: Routes.home,
    element: <PrivateRoute Component={<HomeScreen />} />,
  },
  {
    path: Routes.config,
    element: <PrivateRoute Component={<ConfigScreen />} />,
  },
  {
    path: Routes.members,
    element: <PrivateRoute Component={<MembersScreen />} />,
  },
  {
    path: Routes.professional.list,
    element: <PrivateRoute Component={<ProfessionalListScreen />} />,
  },
  {
    path: Routes.professional.create,
    element: <PrivateRoute Component={<ProfessionalCreateScreen />} />,
  },
  {
    path: Routes.professional.edit,
    element: <PrivateRoute Component={<ProfessionalEditScreen />} />,
  },
  {
    path: Routes.template.list,
    element: <PrivateRoute Component={<TemplateListScreen />} />,
  },
  {
    path: Routes.template.edit,
    element: <PrivateRoute Component={<SheetTemplateEditScreen />} />,
  },
  {
    path: Routes.student.list,
    element: <PrivateRoute Component={<StudentListScreen />} />,
  },
  {
    path: Routes.student.edit,
    element: <PrivateRoute Component={<StudentEditScreen />} />,
  },
  {
    path: Routes.student.createSheet,
    element: <PrivateRoute Component={<SheetCreateScreen />} />,
  },
  {
    path: Routes.exerciseType.list,
    element: <PrivateRoute Component={<ExerciseTypeListScreen />} />,
  },
  {
    path: Routes.exercise.list,
    element: <PrivateRoute Component={<ExerciseListScreen />} />,
  },
]);

export { Router };
