import SheetDialogAddExercise from "../student/components/sheet/sheet-dialog-add-exercise";
import ModalAddLetter from "../student/components/modal/modal-add-letter";
import { ArrowDownIcon, ArrowUpIcon, PlusIcon, Trash2Icon } from "lucide-react";
import { useParams, useSearchParams } from "react-router-dom";
import { Exercise, SheetTemplate } from "@/utils/entities";
import { SheetService } from "@/services/api/sheet";
import { Loading } from "@/components/ui/loading";
import ButtonBack from "@/components/button-back";
import { useQuery } from "@tanstack/react-query";
import { Button } from "@/components/ui/button";
import RootLayout from "@/components/layout";
import { useMemo, useState } from "react";
import {
  CardDescription,
  CardContent,
  CardHeader,
  CardTitle,
  Card,
} from "@/components/ui/card";
import { SheetGridExercisesAdded } from "../student/components/sheet/sheet-list-exercises-added";
import ModalConfirmDelete from "@/components/modal-confirm";

type SheetDialogTypes = "ADD" | "EDIT" | "DELETE" | "LIST_EXECUTION";

export default function SheetTemplateEditScreen() {
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const [dialogType, setDialogType] = useState<SheetDialogTypes>();
  const [activeTrainingIndex, setActiveTrainingIndex] = useState<number>();
  const [isDeleting, setIsDeleting] = useState(false);

  const {
    refetch,
    isFetching,
    data: template,
  } = useQuery({
    queryKey: ["template", id],
    queryFn: () => SheetService.getOneTemplate(id as string),
  });

  const currentTab = useMemo(() => {
    return template?.data[Number(searchParams.get("tabLetterKey") || "0")];
  }, [template, searchParams]);

  const onUpdateTemplate = async (newTemplate: Partial<SheetTemplate>) => {
    await SheetService.updateTemplate(id!, newTemplate);
    await refetch();
  };

  async function onCreateLetter(name: string) {
    await onUpdateTemplate({
      ...template,
      data: [
        ...(template?.data || []),
        { name, exercises: [], index: template?.data.length || 0 },
      ],
    });
  }

  function handleChangeTabLetter(value: string) {
    searchParams.set("tabLetterKey", value);

    setSearchParams(searchParams, { replace: true });
  }

  function handleAddExercise() {
    const index = Number(searchParams.get("tabLetterKey") || "0");
    setActiveTrainingIndex(index);
    setDialogType("ADD");
  }

  function handleCloseDialog() {
    setDialogType(undefined);
    setActiveTrainingIndex(undefined);
  }

  const addExercise = (exercise: Exercise) => {
    template!.data[activeTrainingIndex!].exercises.push({
      ...exercise,
      execution: {
        ...exercise.execution,
        order: template!.data[activeTrainingIndex!].exercises?.length || 1,
      },
    });
    onUpdateTemplate(template!);
  };

  const onRemoveExercise = (exerciseIndex: number) => {
    const trainingIndex = Number(searchParams.get("tabLetterKey") || "0");
    template!.data[trainingIndex].exercises.splice(exerciseIndex, 1);
    onUpdateTemplate(template!);
  };

  async function updateOrderListOfExercises(items: Exercise[]) {
    const trainingIndex = Number(searchParams.get("tabLetterKey") || "0");
    template!.data[trainingIndex].exercises = items.map((item, order) => ({
      ...item,
      execution: { ...item.execution, order },
    }));
    onUpdateTemplate(template!);
  }

  async function moveTrainingToPrevious(currentIndex: number) {
    [
      (template?.data as any[])[currentIndex - 1],
      (template?.data as any[])[currentIndex],
    ] = [template?.data[currentIndex], template?.data[currentIndex - 1]];

    // Atualiza os índices
    (template?.data as any[])[currentIndex - 1].index = currentIndex - 1;
    (template?.data as any[])[currentIndex].index = currentIndex;

    onUpdateTemplate(template!);
  }

  async function moveTrainingToNext(currentIndex: number) {
    // Troca os itens de lugar
    [
      (template?.data as any[])[currentIndex + 1],
      (template?.data as any[])[currentIndex],
    ] = [
      (template?.data as any[])[currentIndex],
      (template?.data as any[])[currentIndex + 1],
    ];

    // Atualiza os índices
    (template?.data as any[])[currentIndex + 1].index = currentIndex + 1;
    (template?.data as any[])[currentIndex].index = currentIndex;

    onUpdateTemplate(template!);
  }

  const updateLetterName = async (name: string) => {
    const currentIndex = Number(searchParams.get("tabLetterKey") || "0");
    (template?.data as any[])[currentIndex].name = name;
    await onUpdateTemplate(template!);
  };

  const deleteCurrentTab = async () => {
    const currentIndex = Number(searchParams.get("tabLetterKey") || "0");
    setIsDeleting(false);
    await onUpdateTemplate({
      ...template,
      data:
        template?.data
          .filter((_, index) => index !== currentIndex)
          .map((item, index) => ({ ...item, index })) || [],
    });
    handleChangeTabLetter("0");
  };

  return (
    <RootLayout>
      {isFetching ? (
        <Loading />
      ) : (
        <Card className="bg-gray-800 p-0">
          <CardHeader>
            <div className="flex text-white items-center">
              <ButtonBack />
              <div className="flex flex-col flex-1">
                <CardTitle className="text-gray-200 flex flex-col md:flex-row md:items-center justify-start">
                  <div>{template?.name}</div>
                </CardTitle>
                <CardDescription>
                  Você esta atualizando um template de ficha
                </CardDescription>
              </div>
            </div>
          </CardHeader>
          <CardContent>
            <div className="grid grid-cols-[300px,1fr] gap-4 overflow-auto">
              <div className="flex flex-col gap-4">
                {template?.data.map((item, index) => (
                  <div className="flex">
                    <div>
                      <button
                        className={`${
                          index === 0
                            ? "opacity-0"
                            : "transition-all text-white hover:bg-white hover:text-black rounded-full"
                        }`}
                        onClick={() => moveTrainingToPrevious(index)}
                      >
                        <ArrowUpIcon />
                      </button>
                      <button
                        className={`${
                          index === template?.data?.length - 1
                            ? "opacity-0"
                            : "transition-all text-white hover:bg-white hover:text-black rounded-full"
                        }`}
                        onClick={() => moveTrainingToNext(index)}
                      >
                        <ArrowDownIcon />
                      </button>
                    </div>
                    <button
                      type="button"
                      key={item.id}
                      onClick={() => handleChangeTabLetter(index.toString())}
                      className={`rounded-md w-full truncate p-2 shadow-sm border border-white hover:opacity-65  transition-all text-left shadow-white ${
                        item.index !== currentTab?.index
                          ? "bg-primary text-white"
                          : "bg-white"
                      }`}
                    >
                      {item.name}
                    </button>
                  </div>
                ))}
                <ModalAddLetter onSave={onCreateLetter} />
              </div>

              <div>
                <div className="flex w-full items-center border-b pb-2">
                  <span className="text-2xl text-white font-semibold">
                    {currentTab?.name || ""}
                  </span>

                  {currentTab && (
                    <div className="flex-1 flex justify-end">
                      <ModalAddLetter
                        isUpdate
                        letter={currentTab?.name}
                        onSave={updateLetterName}
                      />

                      <Button
                        onClick={() => setIsDeleting(true)}
                        className="ml-4"
                        variant={"destructive"}
                      >
                        <Trash2Icon className="mr-2 w-4 h-4" /> Remover "Letra"
                      </Button>
                    </div>
                  )}
                </div>

                <SheetGridExercisesAdded
                  sheetId={id!}
                  exercises={currentTab?.exercises || []}
                  handleRemove={(_exercise, exerciseIndex) =>
                    onRemoveExercise(exerciseIndex)
                  }
                  handleUpdateOrderListOfExercises={(exercises) =>
                    updateOrderListOfExercises(exercises)
                  }
                />

                <Button
                  size="sm"
                  type="button"
                  className="mt-5"
                  variant="secondary"
                  onClick={() => handleAddExercise()}
                >
                  <PlusIcon className="h-4 w-4 mr-1" />
                  Adicionar exercício
                </Button>
              </div>
            </div>
          </CardContent>
        </Card>
      )}

      {dialogType === "ADD" && activeTrainingIndex !== undefined ? (
        <SheetDialogAddExercise
          isOpened={true}
          onChange={addExercise}
          onCancel={() => handleCloseDialog()}
        />
      ) : null}

      <ModalConfirmDelete
        isLoading={false}
        onContinue={deleteCurrentTab}
        isVisible={isDeleting}
        title="Deletando 'Letar'"
        onCancel={() => setIsDeleting(false)}
        description={`Você quer deletar a 'Letra' ${currentTab?.name}?`}
      />
    </RootLayout>
  );
}
