export default function PrivacyScreen() {
  return (
    <div className="p-6 font-sans text-gray-800">
      <h1 className="text-3xl font-bold mb-4">Privacy Policy</h1>
      <p className="text-sm text-gray-600 mb-6">
        Effective Date: December 9, 2024
      </p>

      <p className="mb-4">
        This Privacy Policy explains how we collect, use, and protect your
        information when you use our app ("App"). By using this App, you agree
        to the terms outlined in this Privacy Policy.
      </p>

      <h2 className="text-2xl font-semibold mb-3">Information We Collect</h2>
      <p className="mb-4">
        When you use our App, we may collect the following information:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>First and Last Name</li>
        <li>Email Address</li>
        <li>Phone Number</li>
      </ul>

      <h2 className="text-2xl font-semibold mb-3">
        How We Use Your Information
      </h2>
      <p className="mb-4">
        We use the information we collect for the following purposes:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>To create and manage your account.</li>
        <li>To provide customer support.</li>
        <li>To improve our App and user experience.</li>
      </ul>

      <h2 className="text-2xl font-semibold mb-3">Sharing Your Information</h2>
      <p className="mb-4">
        We do not share your personal information with third parties, except as
        required by law or to provide necessary services (e.g., hosting
        providers).
      </p>

      <h2 className="text-2xl font-semibold mb-3">Data Retention</h2>
      <p className="mb-4">
        We retain your information as long as necessary to provide our services
        or as required by law. You may request the deletion of your data by
        contacting us.
      </p>

      <h2 className="text-2xl font-semibold mb-3">Your Rights</h2>
      <p className="mb-4">
        You have the right to access, update, or delete your personal
        information. To exercise these rights, please contact us using the
        information provided below.
      </p>

      <h2 className="text-2xl font-semibold mb-3">Contact Us</h2>
      <p className="mb-4">
        If you have any questions or concerns about this Privacy Policy, please
        contact us at:
      </p>
      <p className="font-medium">
        Email:{" "}
        <a
          href="mailto:support@gymlabapp.com.br"
          className="text-blue-600 underline"
        >
          support@gymlabapp.com.br
        </a>
      </p>
    </div>
  );
}
