import {
  ExerciseExecution,
  GenericStatus,
  Sheet,
  SheetTemplate,
  Training,
} from "@/utils/entities";
import api from "./config";
import { getStatusText } from "@/utils/functions/parse-data";
import { getDateFormatText } from "@/utils/functions/date-transform";

type SheetCreateOrUpdateInput = {
  id?: string | undefined;
  studentId: string;
  name: string;
};

type RemoveSheetOfStudentInput = {
  sheetId: string;
  studentId: string;
};

function parseItem(item: Sheet) {
  if ("trainings" in item) {
    for (const training of item?.trainings) {
      training.exercises.sort(
        (a, b) => Number(a?.execution?.order) - Number(b?.execution?.order)
      );
    }
  }

  return {
    ...item,
    isActive: item.status === GenericStatus.ACTIVE,
    statusText: getStatusText(item.status as GenericStatus),
    createdAtText: getDateFormatText(item.createdAt),
    expireDateText: getDateFormatText(item.expire_date),
  };
}

async function getAllSheets(): Promise<Sheet[]> {
  const response = await api.get("/sheets");

  return response.data.map((item: Sheet) => parseItem(item));
}

async function getOneSheet(id: string): Promise<Sheet> {
  const response = await api.get(`/sheets/${id}`);

  const sheet = parseItem(response.data);

  return sheet;
}

async function createSheet(data: Partial<Sheet>) {
  const response = await api.post<Sheet>("/sheets", data);

  return response.data;
}

async function copyTemplateSheet(templateId: string, studentId: string) {
  const response = await api.post<Sheet>("sheets/template", {
    studentId,
    templateId,
  });

  return response.data;
}

async function updateSheet(data: Partial<Sheet>) {
  return (await api.put(`/sheets/${data.id}`, data)).data;
}

async function changeStatusSheet(data: Partial<Sheet>) {
  // return (await api.put(`/sheets/${data.id}/change-status`, data)).data;
}

async function createOrUpdateSheet(data: SheetCreateOrUpdateInput) {
  if (data?.id) {
    return updateSheet(data as Partial<Sheet>);
  }

  return createSheet(data as Partial<Sheet>);
}

async function getAllSheetsOfStudents(id: string): Promise<Sheet[]> {
  const response = await api.get(`/student/${id}/sheets`);

  return response.data.map((item: Sheet) => parseItem(item));
}

async function updateExecution(id: string, data: ExerciseExecution) {
  return (await api.put(`/sheets/${id}/execution-update`, data)).data;
}

async function addExercise(id: string, data: any) {
  return (await api.post(`/sheets/${id}/exercise`, data)).data;
}

async function createLetter(id: string, data: Training) {
  return (await api.post(`/sheets/${id}/letter`, data)).data;
}

async function updateLetter(id: string, name: string) {
  return (await api.put(`/sheets/letter/${id}`, { name })).data;
}

async function deleteLetter(id: string) {
  return (await api.delete(`/sheets/letter/${id}`)).data;
}

async function removeExerciseOfTraining(data: any) {
  const { sheetId, trainingId } = data;

  const response = await api.post(
    `/sheets/${sheetId}/training/${trainingId}/remove-exercise`,
    data
  );

  return response?.data;
}

async function removeSheetOfStudent(data: RemoveSheetOfStudentInput) {
  const { sheetId, studentId } = data;

  const response = await api.delete(
    `/sheets/${sheetId}/student/${studentId}/remove`
  );

  return response?.data;
}

async function saveTemplate(name: string) {
  return (await api.post(`/sheets-template`, { name })).data;
}

async function updateTemplate(id: string, body: Partial<SheetTemplate>) {
  return (await api.put(`/sheets-template/${id}`, body)).data;
}

async function getAllTemplates() {
  return (await api.get<SheetTemplate[]>(`/sheets-template`)).data;
}

async function deleteTemplate(id: string) {
  return (await api.delete(`/sheets-template/${id}`)).data;
}

async function getOneTemplate(id: string) {
  const response = await api.get<SheetTemplate>(`/sheets-template/${id}`);

  return response?.data;
}

export const SheetService = {
  addExercise,
  createLetter,
  saveTemplate,
  updateTemplate,
  deleteTemplate,
  getAllTemplates,
  getOneTemplate,
  updateLetter,
  deleteLetter,
  updateExecution,
  copyTemplateSheet,
  getAll: getAllSheets,
  getOne: getOneSheet,
  create: createSheet,
  update: updateSheet,
  removeSheetOfStudent,
  removeExerciseOfTraining,
  changeStatus: changeStatusSheet,
  createOrUpdate: createOrUpdateSheet,
  getAllOfStudent: getAllSheetsOfStudents,
};
